@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/rage-italic');
html, body, #root {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .rage-italic-text {
    font-family: 'rage-italic'; /* Update 'Rage Italic' with the actual font name if using a different font */
}
/* src/fonts/fonts.css */
@font-face {
  font-family: 'RageItalic';
  src: url('./assets/fonts/rage.ttf') format('truetype');
  /* font-weight: 800; */
   /* Normal weight */
  font-style: normal;
}

