.flipimg {
  perspective: 1000px; /* Add perspective to make the flip look more 3D */
}

.flipimg img {
  transition: transform 0.5s ease-in-out; /* Smooth transition for flipping */
  transform-style: preserve-3d; /* Preserve 3D transformations */
}

.flipimg:hover img {
  transform: rotateY(180deg); /* Flip the image on hover */
}
