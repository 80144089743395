.underline::after {
  content: "";
  position: absolute;
  bottom: 40px; /* Adjust as needed */
  left: 0;
  width: 200px;
  height: 2px;
  background: linear-gradient(
    45deg,
    transparent 30%,
    #fff 50%,
    transparent 70%
  ); /* White color */
  transform: rotate(-45deg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 20px;
  /* box-shadow: 0 0 30px 17px #48668577; */
  border-radius: 100px/10px;
}

.coverWrap {
  transform: rotate(130deg);
  position: absolute;
  width: 18em;
  height: 15em;
  left: -3em;
  top: -1em;
}

.coverWrap .numberCover {
  position: absolute;
  background-color: #f5f8f7;
  width: 18em;
  height: 6em;
  border-radius: 50% 50% 0 0;
  border-bottom: 3px solid #f5f8f7;
  transition: all 0.4s;
}

.coverWrap .numberCover::before {
  position: absolute;
  content: "";
  bottom: 5px;
  left: 4em;
  right: 4em;
  top: 5em;
  box-shadow: 0 0 30px 17px #48668577;
  border-radius: 100px/10px;
  z-index: -1;
}

.coverWrap .numberCover::after {
  position: absolute;
  bottom: 0;
  content: "";
  left: -10%;
  width: 120%;
  height: 150%;
  background: radial-gradient(at bottom, #48668533, transparent, transparent);
  z-index: 1;
}

.content {
  margin: 4em 3em 1em 7em;
  position: relative;
}

.coverWrap .numberCover {
  border-radius: 100%;
}
