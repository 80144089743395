.fade-in-left {
  -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.fade-in-top {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.fade-out-left {
  -webkit-animation: fade-out-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-out-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-500px);
    transform: translateX(-500px);
    opacity: 0;
  }
}

.sticky-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white; /* or any color you want */
  z-index: 1000; /* Ensure navbar stays on top */
  transition: all 0.5s ease-in-out; /* Smooth transition */
}

/* Add this to your navbar.css file or within a <style> tag in your React component */

@keyframes spin {
  from {
      transform: rotate(360deg);
  }
  to {
      transform: rotate(0deg);
  }
}

.logo-spin {
  animation: spin 15s linear infinite;
}
