@media (max-width: 374px) {
    .whynotimg {
        width: 409px;
      /* font-size: 24px !important; */
    }
  }
@media (max-width: 600px) {
    .whynotimg {
        width: 480px;
      /* font-size: 24px !important; */
    }
  }