/* Add these styles to your CSS file */
.bw-filter {
    filter: grayscale(100%);
    /* Make the image black and white */
    transition: filter 0.5s ease;
    /* Smooth transition for the filter effect */
}

.bw-filter:hover {
    transform: scale(1.45);
    filter: brightness(100%);
    
    /* Remove the filter to show the full color on hover */
}

/* partner.css */
.slick-slide img {
    margin: auto;
    /* Center align image within its container */
}

/* partner.css */
.bw-filter img {
    transition: transform 0.5s ease, filter 0.5s ease;
    /* Smooth transition for transform and filter changes */
}


/* Adjust padding around slides for spacing */
.slick-list {
    margin: 0 -10px;
    /* Adjust based on your design */
}

.slick-slide>div {
    padding: 0 10px;
    /* Adjust based on your design */
}
@media (max-width: 374px) {
    .responsive-text {
      font-size: 14px !important;
    }
  }
@media (max-width: 374px) {
    .technology {
      font-size: 24px !important;
    }
  }
  