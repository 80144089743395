div.desktop-wrapper {
  position: relative;
  /* padding-top: 25px; */
  padding-bottom: 42.5%;
  height: 0;
  /* padding-right: 14%; */
}
div.desktop-wrapper img {
  object-fit: contain;
  box-sizing: border-box;
  background: url("../../assets/Device\ -\ Macbook\ Pro.svg") center center
    no-repeat;
  background-position: "cover";
  background-size: contain;
  padding: 0% 0% 0% 0%;
  padding-left: 14.4%;
  padding-bottom: 8.87%;
  /* 11.9% 15.5% 14.8% */
  position: absolute;
  top: 15.2%;
  bottom: 0;
  /* right: 25%; */
  /* left : 0; */
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 425px) {
  div.desktop-wrapper img {
    top: 9%;
    
  }
}


@media screen and (min-width: 1020px) {
  /* Your CSS styles go here */
  div.desktop-wrapper img {
    top: 41%;
    /* left: 0; */
    /* padding-left: 14.4%; */
    /* padding-bottom: 8.87%; */
    /* padding-bottom: 11.27%; */
  }
}
@media screen and (min-width: 1250px) {
  /* Your CSS styles go here */
  div.desktop-wrapper img {
    top: 26.5%;
    /* padding-bottom: 11.27%; */
  }
}
@media screen and (min-width: 1280px) {
  /* Your CSS styles go here */
  div.desktop-wrapper img {
    top: 24%;
    /* padding-bottom: 11.27%; */
  }
}
@media screen and (min-width: 1440px) {
  /* Your CSS styles go here */
  div.desktop-wrapper img {
    top: 17.8%;
    /* padding-bottom: 11.27%; */
  }
}
@media screen and (min-width: 1536px) {
  /* Your CSS styles go here */
  div.desktop-wrapper img {
    top: 15.2%;
    /* padding-bottom: 11.27%; */
  }
}
