.flipimg {
    perspective: 1000px; /* Add perspective to make the flip look more 3D */
}

.flipimg img {
    transition: transform 0.5s ease-in-out; /* Smooth transition for flipping */
    transform-style: preserve-3d; /* Preserve 3D transformations */
}

.flipimg:hover img {
    transform: rotateY(180deg); /* Flip the image on hover */
}
.flipimg:hover .readmore{
    text-decoration: underline;
    text-decoration-color: #DD2F29; /* Specify the underline color */
    /* Optionally, you can also control the thickness of the underline */
    text-decoration-thickness: 2px; 
}
.flipimg:hover .readmore1 {
    color: #FFFFFF;
    text-decoration: underline;
    text-decoration-color: #FFFFFF; /* Specify the underline color */
    /* Optionally, you can also control the thickness of the underline */
    text-decoration-thickness: 2px; 
}
.flipimg:hover .GTouch {
    color: #FFFFFF;
    /* text-decoration: underline; */
    /* text-decoration-color: #FFFFFF; Specify the underline color */
    /* Optionally, you can also control the thickness of the underline */
    /* text-decoration-thickness: 2px;  */
}
.flipimg:hover .title{
    color: #DD2F29;
    
    /* text-decoration: underline; */
    /* text-decoration-color: #DD2F29;  */
    /* Specify the underline color */
    /* Optionally, you can also control the thickness of the underline */
    /* text-decoration-thickness: 2px;  */
}
